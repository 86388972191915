var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"footer-props":{ 'itemsPerPageOptions': [50, 100, 200, -1] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\documents\\list.9537')))]),_c('v-spacer'),(_vm.user.adminAccess && _vm.user.adminAccess.documents >= _vm.ACCESS.WRITE)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('src\\views\\documents\\list.981')))],1):_vm._e()],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.trip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip.name)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.remove(item._id)}}},[_vm._v("mdi-close-octagon-outline")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }